import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../context/userContext';
import Token5PurchaseForm from '../context/Token5PurchaseFrom';
import TokenSaleForm4 from '../context/TokenSaleFrom4';
import Footer from './Footer';
import Chart4 from './Chart4';
import { ref, onValue, getDatabase } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import immo from '../Assets/Images/Design sans titre (1).png';
import MYRE from '../Assets/Images/LOGO_ORANGE.png';
import Prévision from '../Assets/Images/Prévision.png';
import Cours from '../Assets/Images/cours.png';
import Grph from '../Assets/Images/Bientôt Disponible.png';
import { useNavigate } from 'react-router-dom';

const Myre2 = () => {
    const auth = getAuth();
    const user = auth.currentUser;
    const database = getDatabase();
    const { toggleModals,currentUser } = useContext(UserContext);
    const [showPurchaseForm, setShowPurchaseForm] = useState(false);
    const [showSaleForm, setShowSaleForm] = useState(false);
    const [price,setPrice]=useState(4);
    const [priceForParse,setPriceForParse]=useState(null);
    const [variationForParse,setVariationForParse]=useState(null);
    const [lastPrice,setLastPrice]=useState(4);
    const [quantiteBnf,setQuantiteBnf]=useState(4);
    const [bnFBalance,setBnFBalance]=useState(4);
    const [totalBalance,setTotalBalance]=useState(4);
    const [noPrices,setNoPrices]=useState(false);
    const K = 0.0001;
	const navigate = useNavigate();
	const handleButtonClick = () => {
		navigate('/');
	};

    let userId;
    if(user){
      userId=user.uid;
    }
    const callAllInformations = ref(database, `globalInformation`);
    const callTokenTransactions = ref(database, `newTokenTransactions`);
    const newTotalBalanceRef = ref(database, `newTotalBalance/${userId}`);
    const totalBalanceRef = ref(database, `totalBalance/${userId}`);
  
    const handleClosePurchaseForm = () => {
      setShowPurchaseForm(false);
    };
  
    const handleCloseSaleForm = () => {
      setShowSaleForm(false);
    };
  
    const showPurchase = () => {
      if(user){
      setShowPurchaseForm(true);
      }
      else{
        toggleModals("signUp");
      }
    }
  
    const showSale = () => {
      if(user){
        setShowSaleForm(true);
      }
      else{
        toggleModals("signUp");
      }
    }
  
    useEffect(() => {
      const getLastPrice = async () => {
        return new Promise((resolve, reject) => {
          onValue(callAllInformations, (snapshot) => {
            resolve(snapshot.val().informationArray[4].lastPrice);
            setPriceForParse(snapshot.val().informationArray[4].price);
            setVariationForParse(snapshot.val().informationArray[4].variation);
          });
        });
      };
      
      const getTotalBalance = async () => {
        return new Promise((resolve, reject) => {
          onValue(totalBalanceRef, (snapshot) => {
            if(snapshot.val() !== null){
              resolve(snapshot.val().balance);
            }
          });
        });
      };
      
      const getBnFBalance = async () => {
        return new Promise((resolve,reject)=>{
          onValue(newTotalBalanceRef, (snapshot)=> {
            if(snapshot.val() !== null){
              resolve(snapshot.val().balance);
            }
          });
        });
      }
  
      const getPrix = async () => {
        return new Promise((resolve, reject) => {
          onValue(callTokenTransactions, async (snapshot) => {
            const tokenTransactions = snapshot.val();
            if (tokenTransactions) {
              const price = await getLastPrice();
              resolve(price);
            } else {
              setNoPrices(true);
              resolve(15);
            }
          });
        });
      };
    
      const getQuantiteBnf = async () => {
        return new Promise((resolve, reject)=> {
          onValue(callAllInformations,(snapshot)=>{
            resolve(snapshot.val().informationArray[1].quantiteBnf)
          });
        });
      };
  
      // Utilisation de la fonction asynchrone dans useEffect
      const fetchData = async () => {
        setPrice(await getPrix());
        setLastPrice(await getLastPrice());
        setQuantiteBnf(await getQuantiteBnf());
        setBnFBalance(await getBnFBalance());
        setTotalBalance(await getTotalBalance());
      };
      fetchData();
      
    }, []);

    return (
      <>
		<div className='bg-whitesmoke'>
		<div className='container mx-auto font-montserrat'>
		<div className='px-6'>
      <div className="">
      <div className="flex items-center justify-center pt-6">
       <img src={MYRE} width="90" height="90" className="mr-4" alt="Logo Myre" />
       <h2 className="text-3xl lg:text-4xl">{currentUser ? 'ProDetection' : 'ProDetection'}</h2>
      </div>
        <div className="my-0">
          <p className="self-stretch text-[17px] text-center leading-6 inline-block mb-12">
          PRODETECTION est née de l'expertise et de la passion d'un ancien compagnon du devoir avec plus de 20 ans d'expérience dans le secteur de la plomberie, de la couverture et de l'assainissement. Notre entreprise est le fruit d'une longue carrière dédiée à l'excellence technique et à un service de qualité irréprochable.

          Chez PRODETECTION, j'utilise ma profonde connaissance du métier pour offrir des solutions de recherche de fuites innovantes et efficaces. Grâce à mon parcours exceptionnel chez les compagnons du devoir, j'ai cultivé une compréhension unique des besoins des clients, ce qui me permet d'adresser chaque problème avec une précision et une efficacité sans égales. Je m'engage à aider ceux dans le besoin, mettant à profit mes compétences hors norme pour assurer la tranquillité et la sécurité de mes clients.

          PRODETECTION, où votre satisfaction est ma mission, et découvrez un partenaire de confiance engagé à résoudre vos problèmes avec excellence et professionnalisme.
          </p>
        </div>

		<div>
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 xl:gap-8">
			<div className='lg:col-span-3'>
				<img src={Grph} className="w-full" alt="Logo Myre"/>
				{/* <Chart4 />*/}
			</div>
			<div className='lg:col-span-1'>
				{/* <img src={immo} width="70" height="70" className="mr-4" alt="Logo Myre" /> */}
				<div className="ml-2 flex flex-col mb-0">
					<p className="text-[17px] leading-7 mb-0 mt-0">
					PRD - BKS/EUR
					</p>
					<div className="flex">
						<p className="text-[#000000] text-[17px] leading-7 mb-0 mt-0" >
							{`${parseFloat(lastPrice).toFixed(2)}€`}
						</p>
						<p className="text-[#62C354] text-[17px] leading-7 ml-1 mb-0 mt-0">
							{`+ ${parseFloat(variationForParse).toFixed(2)}%`}
						</p>
					</div>
					<div className="text-[17px] leading-7 text-slate-400  ">
						Marché ouvert - EN EUR
					</div>
				</div>

				<div className="flex flex-col">
				<button onClick={showPurchase} className="cursor-pointer rounded-lg py-1.5 px-5 mt-8 font-medium bg-gradient-to-br from-[#956BAC] to-[#DA6B7A] hover:bg-gradient-to-tr text-lg text-white">Acheter</button>
					{showPurchaseForm && (
					<Token5PurchaseForm
						onClose={handleClosePurchaseForm}
						totalBalance={totalBalance}
						quantiteBnf={quantiteBnf} 
						prix={price}
						lastPrice={lastPrice}
						K={K}
					/>
					)}
					<button onClick={showSale} className="cursor-pointer rounded-lg py-1.5 px-5 mt-2 font-medium bg-gradient-to-br from-[#956BAC] to-[#DA6B7A] hover:bg-gradient-to-tr text-lg text-white">
					Vendre
					</button>
					{showSaleForm && (
					<TokenSaleForm4
						onClose={handleCloseSaleForm}
						newTotalBalance={bnFBalance}
						lastPrice={lastPrice}
						prix={price}
						quantiteBnf={quantiteBnf}
						K={K}
					/>
					)}
				</div>
			</div>
		</div>
          
          {/*<div className='flex items-left justify-start'>
                    <img src={Prévision} width="15" height="15" className="left mt-2 ml-2 mr-2" alt="Logo Myre" />
                    <p className="self-stretch text-center text-[10px] leading-[20px] inline-block ">
                        Prévision
                    </p>
                    <div className='flex items-left justify-start'>
                        <img src={Cours} width="15" height="15" className="left mt-2 ml-2 mr-2" alt="Logo Myre" />
                        <p className="self-stretch text-center text-[10px] leading-[20px] inline-block ">
                            Cours
                        </p>
                    </div>
                </div> */}

          <div className="my-12 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
            <div className="bg-white rounded-lg py-6 px-6">
                <h3 className='text-[17px] mb-2 text-slate-400 font-medium'>Cours de l'action :</h3>
                <b className="text-lg font-semibold text-green-500 flex items-center">
                {`${parseFloat(lastPrice).toFixed(2)} €`}
                <img
                    className="h-6 w-6 ml-2"
                    alt=""
                    src="/increase.svg"
                />
                </b>

            </div>
            <div className="bg-white rounded-lg py-6 px-6 ">
                <h3 className='text-[17px] mb-2 text-slate-400 font-medium'>Siège social :</h3>
                <b className="text-lg font-semibold">
                Lyon, France
                </b>
            </div>
            <div className="bg-white rounded-lg py-6 px-6 ">
                <h3 className='text-[17px] mb-2 text-slate-400 font-medium'>Date de création :</h3>
                <b className="text-lg font-semibold">
                  22/04/2024
                </b>
            </div>
            <div className="bg-white rounded-lg py-6 px-6">
                <h3 className='text-[17px] mb-2 text-slate-400 font-medium'>PDG :</h3>
                <b className="text-lg font-semibold">
                  Benjamin Standaert
                </b>
            </div>
            <div className="bg-white rounded-lg py-6 px-6 ">
                <h3 className='text-[17px] mb-2 text-slate-400 font-medium'>Forme Juridique :</h3>
                <b className="text-lg font-semibold">
                  SAS
                </b>
            </div>
            <div className="bg-white rounded-lg py-6 px-6 ">
                <h3 className='text-[17px] mb-2 text-slate-400 font-medium'>Chiffre d'affaire :</h3>
                <b className="text-lg font-semibold">
                  300 000 €
                </b>
            </div>
            <div className="bg-white rounded-lg py-6 px-6 ">
                <h3 className='text-[17px] mb-2 text-slate-400 font-medium'>Nombre de CART en circulation :</h3>
                <b className="text-lg font-semibold">
                {`${parseFloat(quantiteBnf).toFixed(3)} / 10 000`}
                </b>
            </div>
            <div className="bg-white rounded-lg py-6 px-6 ">
                <h3 className='text-[17px] mb-2 text-slate-400 font-medium'>Capitalisation :</h3>
                <b className="text-lg font-semibold">
                 650 000 €
                </b>
            </div>
          </div>
        </div>
		<div className='flex pb-4'>
			<button onClick={handleButtonClick} className="cursor-pointer mx-auto rounded-md font-montserrat font-medium px-6 py-2.5 bg-gradient-to-br from-[#956BAC] to-[#DA6B7A] hover:bg-gradient-to-tr text-base text-white mt-6">
				Projet de l'entreprise
			</button>
		</div>
      </div>
	  </div>
	  </div>
	  <Footer />
	  </div>
        
        </>
    );
  };

  export default Myre2;

import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getDatabase, ref, update, push, get, onValue, off } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATA_BASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export default function TokenPurchaseForm({ onClose }) {
  const navigate = useNavigate();
  const [validation, setValidation] = useState("");
  const [amount, setAmount] = useState(0);
  const [transactionFee, setTransactionFee] = useState(0);
  const [netAmount, setNetAmount] = useState(0);
  const transactionFeeRate = 0.03; // 3% frais de transaction
  const auth = getAuth();
  const user = auth.currentUser;
  const inputs = useRef([]);

  // UID de l'administrateur
  const adminUserId = "eoIP154cJmT8xHD9syk7Njg5cj43";  // L'UID du compte administrateur
  const addInputs = (el) => {
    if (el && !inputs.current.includes(el)) {
      inputs.current.push(el);
    }
  };
  const formRef = useRef();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate("/login");
      }
    });

    return () => {
      unsubscribe();
    };
  }, [auth, navigate]);

  // Fonction pour enregistrer une transaction "Frais" sur le compte de l'administrateur
  const updateAdminBalance = async (feeAmount, userId) => {
    const adminTokenBalanceRef = ref(database, `users/${adminUserId}/tokenBalance`);
    const adminTransactionRef = push(ref(database, `transactions/${adminUserId}`)); // Créer une nouvelle transaction pour l'administrateur
    const adminSnapshot = await get(adminTokenBalanceRef);
    let adminBalance = adminSnapshot.val()?.balance || 0;

    // Ajout des frais au solde de l'administrateur
    adminBalance += feeAmount;

    // Mise à jour du solde de l'administrateur
    await update(adminTokenBalanceRef, {
      balance: adminBalance,
    });

    // Enregistrer la transaction comme "Frais de transaction"
    const adminTransaction = {
      amount: feeAmount,
      timestamp: Date.now(),
      EntrepriseName: "Frais",  // Mention "Frais" pour la transaction de l'administrateur
      sourceUserId: userId, // Optionnel: pour savoir d'où provient la transaction
    };

    // Ajouter la transaction "Frais" au compte de l'administrateur
    await update(adminTransactionRef, adminTransaction);
  };

  // Fonction pour mettre à jour le solde utilisateur et enregistrer les frais
  const updateUserTokenBalance = async (user, netAmount, transactionFee) => {
    const userId = user.uid;
    const userTokenBalanceRef = ref(database, `users/${userId}/tokenBalance`);
    const transactionRef = push(ref(database, `transactions/${userId}`));
    const feeRef = push(ref(database, `fees`));

    const newTransaction = {
      amount: netAmount,
      timestamp: Date.now(),
      EntrepriseName: "Dépôt",  // Nom de la transaction défini à "Dépôt"
      lastPrice: netAmount,
      transactionFee: transactionFee,
    };

    // Mettre à jour le solde de l'utilisateur
    await update(userTokenBalanceRef, {
      balance: netAmount,
    });

    // Enregistrer la transaction utilisateur
    await update(transactionRef, newTransaction);

    // Stocker les frais de transaction
    const feeData = {
      userId: userId,
      feeAmount: transactionFee,
      timestamp: Date.now(),
    };
    await update(feeRef, feeData);

    // Ajouter les frais au solde de l'administrateur et créer une transaction "Frais"
    await updateAdminBalance(transactionFee, userId);  // Crédite les frais sur le compte administrateur et enregistre une transaction
  };

  // Calcul des frais et du montant net
  const calculateFees = (inputAmount) => {
    const parsedAmount = parseFloat(inputAmount) || 0;
    const fee = parsedAmount * transactionFeeRate;
    const net = parsedAmount - fee;
    setTransactionFee(fee);
    setNetAmount(net);
  };

  // Fonction pour calculer le solde total de l'utilisateur
  const calculateTotalBalance = (userId) => {
    const transactionRef = ref(database, `transactions/${userId}`);
    const totalBalanceRef = ref(database, `totalBalance/${userId}`);

    const updateTotalBalance = (snapshot) => {
      const transactions = snapshot.val();
      let totalBalance = 0;

      if (transactions) {
        Object.values(transactions).forEach((transaction) => {
          totalBalance += parseFloat(transaction.amount);
        });
      }

      update(totalBalanceRef, {
        balance: totalBalance,
      });
    };

    onValue(transactionRef, updateTotalBalance);

    return () => {
      off(transactionRef, updateTotalBalance);
    };
  };

  // Gestion de la soumission du formulaire
  const handleForm = async (e) => {
    e.preventDefault();

    const amountInput = inputs.current[0].value;
    const paymentMethod = inputs.current[1].value;

    try {
      if (amountInput === "" || paymentMethod === "") {
        setValidation("Veuillez remplir tous les champs.");
        return;
      }

      const tokenMax = 100000;
      const tokenAmount = parseFloat(amountInput);
      const token1AmountRequired = 10; // Montant minimum de dépôt

      if (tokenAmount < token1AmountRequired) {
        setValidation("Vous devez déposer au moins 10 euros.");
        return;
      }

      if (tokenAmount > tokenMax) {
        setValidation("Le maximum de dépôt est de 100 000 euros.");
        return;
      }

      await updateUserTokenBalance(user, netAmount, transactionFee);

      formRef.current.reset();
      setValidation("");

      navigate("/mon-compte");

      onClose();
    } catch (error) {
      console.error("Erreur lors de l'achat de jetons:", error);
      setValidation("Une erreur s'est produite lors de l'achat de jetons.");
    }
  };

  // Met à jour les frais et le montant net lorsque l'utilisateur entre un montant
  const handleAmountChange = (e) => {
    setAmount(e.target.value);
    calculateFees(e.target.value);
  };

  useEffect(() => {
    if (user) {
      calculateTotalBalance(user.uid);
    }
  }, [user]);

  const closeModal = () => {
    setValidation("");
    if (typeof onClose === "function") {
      onClose();
    }
  };

  return (
    <>
      <div
        onClick={closeModal}
        className="fixed top-0 left-0 w-full h-full flex items-center justify-center font-montserrat bg-black bg-opacity-50 z-10"
      >
        <div
          className="bg-whitesmoke rounded-xl overflow-hidden shadow-lg w-[500px]"
          style={{ zIndex: "1050", maxWidth: "600px", width: "90%" }}
          onClick={(e) => { e.stopPropagation(); }}
        >
          <div className="bg-gradient-to-br from-[#956BAC] to-[#DA6B7A] flex text-light justify-center">
            <h5 className="text-xl font-medium text-white">Déposer des euros</h5>
            <span style={{ cursor: "pointer" }} onClick={closeModal}>
              <i className="fa-solid fa-xmark fa-1x"></i>
            </span>
          </div>
          <form ref={formRef} onSubmit={handleForm} className="p-6">
            <div className="mb-4">
              <label htmlFor="amount" className="block font-montserrat mb-1">
                Montant :
              </label>
              <input
                type="text"
                className="form-control w-full text-[17px] rounded-lg bg-white border border-slate-400 py-2.5 px-4 box-border font-montserrat"
                pattern="[0-9]+([,\.][0-9]+)?"
                placeholder="Entrez le montant du dépôt"
                min={10}
                id="amount"
                ref={addInputs}
                value={amount}
                onChange={handleAmountChange}
              />
            </div>

            {/* Affichage des frais de transaction et du montant net */}
            {amount >= 10 && (
              <div className="mb-4">
                <p>Frais de transaction (3%) : {transactionFee.toFixed(2)} €</p>
                <p>Montant net crédité : {netAmount.toFixed(2)} €</p>
              </div>
            )}

            <div className="mb-6">
              <label htmlFor="paymentMethods" className="block font-montserrat mb-1">
                Méthode de paiement :
              </label>
              <input
                type="text"
                className="form-control w-full text-[17px] rounded-lg bg-white border border-slate-400 py-2.5 px-4 box-border font-montserrat"
                id="paymentMethod"
                ref={addInputs}
              />
            </div>

            <div className="text-center">
              <button
                type="submit"
                className="cursor-pointer mx-auto rounded-md font-montserrat font-medium px-6 py-2.5 bg-gradient-to-br from-[#956BAC] to-[#DA6B7A] hover:bg-gradient-to-tr text-base text-white"
              >
                Acheter
              </button>
            </div>

            {validation && (
              <div className="alert alert-danger mt-3">{validation}</div>
            )}
          </form>
        </div>
      </div>
    </>
  );
}

import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../context/userContext';
import Token4PurchaseForm from '../context/Token4PurchaseFrom';
import TokenSaleForm3 from '../context/TokenSalefrom3';
import Footer from './Footer';
import Chart3 from './Chart3';
import { ref, onValue, getDatabase } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import immo from '../Assets/Images/Finance.png';
import BKS from '../Assets/Images/icon.png';
import Prévision from '../Assets/Images/Prévision.png';
import Cours from '../Assets/Images/cours.png';
import Grph from '../Assets/Images/graph.png';
import { useNavigate } from 'react-router-dom';

const Backstorm = () => {
    const auth = getAuth();
    const user = auth.currentUser;
    const database = getDatabase();
    const { toggleModals,currentUser } = useContext(UserContext);
    const [showPurchaseForm, setShowPurchaseForm] = useState(false);
    const [showSaleForm, setShowSaleForm] = useState(false);
    const [price,setPrice]=useState(2);
    const [priceForParse,setPriceForParse]=useState(null);
    const [variationForParse,setVariationForParse]=useState(null);
    const [lastPrice,setLastPrice]=useState(2);
    const [quantiteBnf,setQuantiteBnf]=useState(2);
    const [bnFBalance,setBnFBalance]=useState(2);
    const [totalBalance,setTotalBalance]=useState(2);
    const [noPrices,setNoPrices]=useState(false);
    const K = 0.0001;
	const navigate = useNavigate();
	const handleButtonClick = () => {
		navigate('/');
	};

    let userId;
    if(user){
      userId=user.uid;
    }
    const callAllInformations = ref(database, `globalInformation`);
    const callTokenTransactions = ref(database, `newTokenTransactions`);
    const newTotalBalanceRef = ref(database, `newTotalBalance/${userId}`);
    const totalBalanceRef = ref(database, `totalBalance/${userId}`);
  
  
  
  
    const handleClosePurchaseForm = () => {
      setShowPurchaseForm(false);
    };
  
    const handleCloseSaleForm = () => {
      setShowSaleForm(false);
    };
  
    const showPurchase = () => {
      if(user){
        //setShowPurchaseForm(true);
      }
      else{
        toggleModals("signUp");
      }
    }
  
    const showSale = () => {
      if(user){
        //setShowSaleForm(true);
      }
      else{
        toggleModals("signUp");
      }
    }
  
    useEffect(() => {
      const getLastPrice = async () => {
        return new Promise((resolve, reject) => {
          onValue(callAllInformations, (snapshot) => {
            resolve(snapshot.val().informationArray[2].lastPrice);
            setPriceForParse(snapshot.val().informationArray[2].price);
            setVariationForParse(snapshot.val().informationArray[2].variation);
          });
        });
      };
      
      const getTotalBalance = async () => {
        return new Promise((resolve, reject) => {
          onValue(totalBalanceRef, (snapshot) => {
            if(snapshot.val() !== null){
              resolve(snapshot.val().balance);
            }
          });
        });
      };
      
      const getBnFBalance = async () => {
        return new Promise((resolve,reject)=>{
          onValue(newTotalBalanceRef, (snapshot)=> {
            if(snapshot.val() !== null){
              resolve(snapshot.val().balance);
            }
          });
        });
      }
  
      const getPrix = async () => {
        return new Promise((resolve, reject) => {
          onValue(callTokenTransactions, async (snapshot) => {
            const tokenTransactions = snapshot.val();
            if (tokenTransactions) {
              const price = await getLastPrice();
              resolve(price);
            } else {
              setNoPrices(true);
              resolve(1700);
            }
          });
        });
      };
    
      const getQuantiteBnf = async () => {
        return new Promise((resolve, reject)=> {
          onValue(callAllInformations,(snapshot)=>{
            resolve(snapshot.val().informationArray[1].quantiteBnf)
          });
        });
      };
  
      // Utilisation de la fonction asynchrone dans useEffect
      const fetchData = async () => {
        setPrice(await getPrix());
        setLastPrice(await getLastPrice());
        setQuantiteBnf(await getQuantiteBnf());
        setBnFBalance(await getBnFBalance());
        setTotalBalance(await getTotalBalance());
      };
      fetchData();
      
    }, []);

    return (
      <>
		<div className='bg-whitesmoke'>
		<div className='container mx-auto font-montserrat'>
		<div className='px-6'>
      <div className="">
      <div className="flex items-center justify-center pt-6">
       <img src={BKS} width="40" height="50" className="mr-4" alt="Logo Myre" />
       <h2 className="text-3xl lg:text-4xl">{currentUser ? 'Backstorm' : 'Backstorm'}</h2>
      </div>
        <div className="my-0">
          <p className="self-stretch text-[17px] text-center leading-6 inline-block mb-12">
          Backstorm crée les conditions d’investissements en mettant en relation les petites et moyennes entreprises et tout investisseur. L’entreprise peut donc assurer sa croissance, se moderniser et se pérenniser par le biais de ces fonds sans être contrainte par les limites des levées de fonds classiques telles que le crowdfunding, un prêt bancaire et tout type de levier financier, nécessitant dans la majorité des cas du temps et de nombreuses démarches administratives notamment.

Pour se faire, Backstorm simplifie la connexion entre les deux partis en proposant des contrats à rendement, c’est à dire des valeurs mobilières qui représente une fraction des bénéfices d'une entreprise listée dans l'écosystème Backstorm.
          
          </p>
        </div>

		<div>
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 xl:gap-8">
			<div className='lg:col-span-3'>
				{/* <img src={Grph} className="w-full" alt="Logo Myre"/> */}
				<Chart3 />
			</div>
			<div className='lg:col-span-1'>
				{/* <img src={immo} width="70" height="70" className="mr-4" alt="Logo Myre" /> */}
				<div className="ml-2 flex flex-col mb-0">
					<p className="text-[17px] leading-7 mb-0 mt-0">
					BKT - BKS/EUR
					</p>
					<div className="flex">
						<p className="text-[#000000] text-[17px] leading-7 mb-0 mt-0" >
							{`${parseFloat(lastPrice).toFixed(2)}€`}
						</p>
						<p className="text-[#62C354] text-[17px] leading-7 ml-1 mb-0 mt-0">
							{`+ ${parseFloat(variationForParse).toFixed(2)}%`}
						</p>
					</div>
					<div className="text-[17px] leading-7 text-slate-400  ">
						Marché ouvert - EN EUR
					</div>
				</div>

				<div className="flex flex-col">
				<button onClick={showPurchase} className="cursor-pointer rounded-lg py-1.5 px-5 mt-8 font-medium bg-gradient-to-br from-[#956BAC] to-[#DA6B7A] hover:bg-gradient-to-tr text-lg text-white">Acheter</button>
					{showPurchaseForm && (
					<Token4PurchaseForm
						onClose={handleClosePurchaseForm}
						totalBalance={totalBalance}
						quantiteBnf={quantiteBnf} 
						prix={price}
						lastPrice={lastPrice}
						K={K}
					/>
					)}
					<button onClick={showSale} className="cursor-pointer rounded-lg py-1.5 px-5 mt-2 font-medium bg-gradient-to-br from-[#956BAC] to-[#DA6B7A] hover:bg-gradient-to-tr text-lg text-white">
					Vendre
					</button>
					{showSaleForm && (
					<TokenSaleForm3
						onClose={handleCloseSaleForm}
						newTotalBalance={bnFBalance}
						lastPrice={lastPrice}
						prix={price}
						quantiteBnf={quantiteBnf}
						K={K}
					/>
					)}
				</div>
			</div>
		</div>
          
          {/*<div className='flex items-left justify-start'>
                    <img src={Prévision} width="15" height="15" className="left mt-2 ml-2 mr-2" alt="Logo Myre" />
                    <p className="self-stretch text-center text-[10px] leading-[20px] inline-block ">
                        Prévision
                    </p>
                    <div className='flex items-left justify-start'>
                        <img src={Cours} width="15" height="15" className="left mt-2 ml-2 mr-2" alt="Logo Myre" />
                        <p className="self-stretch text-center text-[10px] leading-[20px] inline-block ">
                            Cours
                        </p>
                    </div>
                </div> */}

          <div className="my-12 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
            <div className="bg-white rounded-lg py-6 px-6">
                <h3 className='text-[17px] mb-2 text-slate-400 font-medium'>Cours de l'action :</h3>
                <b className="text-lg font-semibold text-green-500 flex items-center">
                {`${parseFloat(lastPrice).toFixed(2)} €`}
                <img
                    className="h-6 w-6 ml-2"
                    alt=""
                    src="/increase.svg"
                />
                </b>
            </div>
            <div className="bg-white rounded-lg py-6 px-6">
                <h3 className='text-[17px] mb-2 text-slate-400 font-medium'>Siège social :</h3>
                <b className="text-lg font-semibold">
                Paris, France
                </b>
            </div>
            <div className="bg-white rounded-lg py-6 px-6">
                <h3 className='text-[17px] mb-2 text-slate-400 font-medium'>Date de création :</h3>
                <b className="text-lg font-semibold">
                  01/02/2020
                </b>
            </div>
            <div className="bg-white rounded-lg py-6 px-6">
                <h3 className='text-[17px] mb-2 text-slate-400 font-medium'>PDG :</h3>
                <b className="text-lg font-semibold">
                  Félix Odzali
                </b>
            </div>
            <div className="bg-white rounded-lg py-6 px-6">
                <h3 className='text-[17px] mb-2 text-slate-400 font-medium'>Forme Juridique :</h3>
                <b className="text-lg font-semibold">
                  SA
                </b>
            </div>
            <div className="bg-white rounded-lg py-6 px-6">
                <h3 className='text-[17px] mb-2 text-slate-400 font-medium'>Chiffre d'affaire :</h3>
                <b className="text-lg font-semibold">
                  200 000 €
                </b>
            </div>
            <div className="bg-white rounded-lg py-6 px-6">
                <h3 className='text-[17px] mb-2 text-slate-400 font-medium'>Nombre de CART en circulation :</h3>
                <b className="text-lg font-semibold">
                {`${parseFloat(quantiteBnf).toFixed(3)} / 10 000`}
                </b>
            </div>
            <div className="bg-white rounded-lg py-6 px-6">
                <h3 className='text-[17px] mb-2 text-slate-400 font-medium'>Capitalisation :</h3>
                <b className="text-lg font-semibold">
                400 000 €
                </b>
            </div>
          </div>
        </div>
		<div className='flex pb-4'>
			<button onClick={handleButtonClick} className="cursor-pointer mx-auto rounded-md font-montserrat font-medium px-6 py-2.5 bg-gradient-to-br from-[#956BAC] to-[#DA6B7A] hover:bg-gradient-to-tr text-base text-white mt-6">
				Projet de l'entreprise
			</button>
		</div>
      </div>
	  </div>
	  </div>
	  <Footer />
	  </div>
        
        </>
    );
  };

  export default Backstorm;
